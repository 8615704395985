import { styled } from "@mui/system";
import CustomImage from "../custom-image/custom-image.component";
import Grid from "@mui/material/Grid";

export const InnerWrapper = styled("div")`
  text-align: center;
  max-width: 140px;
  margin: auto 0 auto auto;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: auto;
    padding-top: 2em;
    padding-bottom: 2em;
  }
`;

export const Text = styled("p")<any>`
  margin-top: 0;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(22)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 1em;
  }
`;

export const Flex = styled("div")<any>`
  display: flex;
  justify-content: ${({ showLogo }: any) =>
    showLogo ? "space-between" : "center"};
  max-width: 60px;
  margin: auto;

  svg {
    fill: white;
    width: 25px;
    height: auto;
  }
`;

export const GridItem = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 180px;
    margin: 0 0 0 auto;
  }
`;

export const Image = styled(CustomImage)`
  text-align: right;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    text-align: center;
    margin-top: 3em;
  }
`;
