import { styled } from "@mui/system";
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component";

export const Main = styled("main")``;

export const Wrapper = styled(SectionWrapper)<any>`
  background-color: #000000;
  min-height: 100vh;
  width: 100%;

  h2 {
    margin-bottom: 4rem;
    font-size: ${({ theme }) => theme.typography.pxToRem(32)};
    line-height: ${({ theme }) => theme.typography.pxToRem(28)};

    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(20)};
      line-height: ${({ theme }) => theme.typography.pxToRem(20)};
    }
  }
`;

export const InnerWrapper = styled("div")<any>`
  text-align: center;
  margin-bottom: 3rem;

  img {
    width: 200px;
    height: 200px;
    margin-bottom: 2rem;
  }

  a,
  p {
    color: #ffffff;
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
    line-height: ${({ theme }) => theme.typography.pxToRem(20)};

    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(20)};
      line-height: ${({ theme }) => theme.typography.pxToRem(14)};
    }
  }
`;

export const List = styled("ol")<any>`
  li {
    margin-bottom: 0.5rem;
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
    line-height: ${({ theme }) => theme.typography.pxToRem(20)};

    a {
      color: #ffffff;
    }
  }

  img {
    display: block;
    width: 600px;
    max-width: 100%;
    height: auto;
    margin: 1rem auto;
  }
`;
