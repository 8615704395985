import { useContext } from "react";

interface GetContextProps {
  jsonFireBaseData: any;
  jsonInstagramData: {};
  loading: boolean;
  show: boolean;
  setShow: (bool: boolean) => void;
  showLogo: boolean;
}

export const GetContext = (context: any) => {
  const {
    jsonFireBaseData,
    jsonInstagramData,
    loading,
    show,
    setShow,
    showLogo,
  }: GetContextProps = useContext(context) ?? {};

  return {
    jsonFireBaseData,
    jsonInstagramData,
    loading,
    show,
    setShow,
    showLogo,
  };
};
