import React from "react";

import Game from "../../components/game/App.js";
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component";
import Slider from "../../components/slider/slider.component";
import { GlobalDataContext } from "../../context/provider";
import GeneralHero from "../../layouts/general-hero/general-hero.component";
import { GetContext } from "../../utils/utils";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Helmet } from "react-helmet-async";

const Home = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const { jsonFireBaseData } = GetContext(GlobalDataContext);

  if (!jsonFireBaseData) {
    return;
  }

  return (
    <>
      <Helmet>
        <title>Pagina principal, Yomiko Dolly!</title>
      </Helmet>
      <GeneralHero data={{ jsonFireBaseData }} />
      <SectionWrapper maxWidth="md">
        <Slider data={{ jsonFireBaseData }} className="height" />
      </SectionWrapper>
      <div className="background-image">
        <SectionWrapper maxWidth="sm">
          <div id="root-2" style={{ paddingTop: isMd ? "5rem" : "2.5rem" }}>
            <Game />
          </div>
        </SectionWrapper>
      </div>
    </>
  );
};

export default Home;
