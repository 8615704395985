import React from "react";

import SectionWrapper from "../../components/section-wrapper/section-wrapper.component";
import { GlobalDataContext } from "../../context/provider";
//import GeneralHero from "../../layouts/general-hero/general-hero.component";
import { GetContext } from "../../utils/utils";
// import DeunaQR from "../../assets/images/deuna-qr.jpg";

import PayPalQR from "../../assets/images/paypal-qr.png";
import DeUnaQR from "../../assets/images/deuna-qr.png";
import PayPalLogo from "../../assets/images/paypal-logo.png";
import DeUnaLogo from "../../assets/images/deuna-logo.png";
import PichinchaLogo from "../../assets/images/banco-pichincha.png";
import data from "../../locales/en/translations.json";

import * as S from "./donations.styles";

import { Helmet } from "react-helmet-async";
import { Grid } from "@mui/material";

const Donations = () => {
  const { jsonFireBaseData } = GetContext(GlobalDataContext);

  if (!jsonFireBaseData) {
    return;
  }

  const dataType: any = data;

  return (
    <>
      <Helmet>
        <title>Donaciones, Yomiko Dolly!</title>
      </Helmet>
      <SectionWrapper maxWidth="md">
        <h2 style={{ textAlign: "center" }}>
          Apoyame donando a mi cuenta de Paypal o del Banco del Pichincha
          escaneando el codigo QR en las 3 opciones de abajo (para mejores resultados escanea el codigo QR, debes instalar la aplicacion)!
        </h2>
        <S.GridContainer container alignItems="center">
          <Grid item md={4} xs={12}>
            <a href={dataType.social["paypal"]}>
              <div>
                <img src={PayPalQR} title="paypal-qr" />
              </div>
              <div>
                <img src={PayPalLogo} title="paypal-logo" />
              </div>
            </a>
          </Grid>
          <Grid item md={4} xs={12} style={{ marginBottom: "1,5rem" }}>
            <p>o directo a mi cuenta 2209190514</p>
            <img src={PichinchaLogo} title="pichincha-logo" />
          </Grid>
          <Grid item md={4} xs={12}>
            <a href={dataType.social["deuna"]}>
              <div>
                <img src={DeUnaQR} title="deuna-qr" />
              </div>
              <div>
                <img src={DeUnaLogo} title="deuna-logo" />
              </div>
            </a>
          </Grid>
        </S.GridContainer>
      </SectionWrapper>
    </>
  );
};

export default Donations;
