import { styled } from "@mui/system";

import { Container } from "@mui/material";
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component";

export const BackgroundImage = styled(CustomBgImage)<any>`
  .gatsby-image-wrapper {
    img {
      object-fit: cover;
      width: 100%;
      object-position: 0px -650px;

      ${({ theme }) => theme.breakpoints.down("sm")} {
        display: none;
      }
    }

    video {
      display: block;
      margin: auto;
      object-fit: cover;
      width: 100%;

      ${({ theme }) => theme.breakpoints.down("sm")} {
        width: 100%;
        height: 400px;
      }
    }
  }
`;

export const Wrapper = styled("div")`
  position: relative;
  height: 100%;
  min-height: 100vh;

  &:before {
    position: absolute;
    left: 0;
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(10, 10, 10, 0.1);
    z-index: -1;
    padding-bottom: 0.025rem;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      display: none;
    }
  }
`;

export const InnerWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0;

  > div {
    height: 100vh;
  }
`;

export const VideoWrapper = styled(CustomBgImage)<any>`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    ${({ theme }) => theme.breakpoints.up("sm")} {
      display: none;
    }
  }

  video {
    display: block;
    margin: auto;
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
`;

export const Shadow = styled("div")`
  position: relative;
  height: 100%;
  min-height: 100vh;

  &:before {
    position: absolute;
    left: 0;
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(10, 10, 10, 0.1);
    z-index: -1;
    padding-bottom: 0.025em;

    ${({ theme }) => theme.breakpoints.up("sm")} {
      display: none;
    }
  }
`;

export const PaddingWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 2em;
`;

export const Img = styled("img")`
  object-fit: cover;
  border-radius: 100%;
  width: 150px !important;
  height: 150px !important;
`;

export const Title = styled("h1")<any>`
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: ${({ theme }) => theme.typography.pxToRem(40)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(40)};
    line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  }
`;

export const Text = styled("h2")<any>`
  margin-top: 0.5em;
  margin-bottom: 1em;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(30)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    line-height: ${({ theme }) => theme.typography.pxToRem(28)};
  }
`;

export const ButtonWrapper = styled("div")<any>`
  a {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    max-width: 400px;
    margin-bottom: 1em;
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  }
`;
