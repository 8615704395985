import React from "react";
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper";

import { SwiperSlide } from "swiper/react";
import * as S from "./slider.styles";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

interface SliderProps {
  className: string;
  data: { jsonFireBaseData: { carousel: { items: any[] } } };
}

const Slider = ({ data }: SliderProps) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const { jsonFireBaseData } = data;
  const { carousel } = jsonFireBaseData;

  return (
    <S.Wrapper>
      {isLg && (
        <div className="swiper-button-prev">
          <ChevronLeftIcon />
        </div>
      )}
      <S.SwiperContainer
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        onSwiper={(swiper) => {
          console.log(swiper);
        }}
        onSlideChange={() => console.log("slide change")}
      >
        {carousel &&
          carousel.items.length > 0 &&
          carousel.items.map(
            (item: { image: string; text: string }, index: number) => (
              <SwiperSlide key={`slider-${index}`}>
                <S.Background>
                  <img src={item.image} loading="lazy" />
                </S.Background>
                <S.Text>{item.text}</S.Text>
              </SwiperSlide>
            )
          )}
      </S.SwiperContainer>
      {!isLg && (
        <S.Flex>
          <div className="swiper-button-prev">
            <ChevronLeftIcon />
          </div>
          <div className="swiper-button-next">
            <ChevronRightIcon />
          </div>
        </S.Flex>
      )}
      {isLg && (
        <div className="swiper-button-next">
          <ChevronRightIcon />
        </div>
      )}
    </S.Wrapper>
  );
};

export default Slider;
