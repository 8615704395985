import { styled } from "@mui/system";

export const Background = styled("div")`
  position: fixed;
  z-index: 100000;
  background: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  height: 100%;
  top: 0;

  &.animation {
    top: -100%;
    transition: top 2s;
    transition-timing-function: linear;
    transition-delay: 2s;
    animation-name: myAnimation;
    animation-duration: 2s;
  }

  @keyframes myAnimation {
    from {
      visibility: visible;
      transform: rotate(0deg);
    }
    to {
      visibility: hidden;
      transform: rotate(360deg);
    }
  }
`;

export const Wrapper = styled("div")`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  > div {
    margin: auto;

    &:before,
    &:after {
      /* background-color: #0000ff; */
      background-color: #ecd39f;
    }
  }
`;

export const Loading = styled("h2")`
  color: ${({ theme }) => theme.palette.secondary.main};
`;
