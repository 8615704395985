import React, { createContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

interface GlobalDataContextProps {
  jsonFireBaseData: any;
  jsonInstagramData: {};
  loading: boolean;
  show: boolean;
  setShow: (bool: boolean) => void;
  showLogo: boolean;
}

interface ContextPropsChildren {
  children?: JSX.Element | JSX.Element[];
}

export const GlobalDataContext = createContext<GlobalDataContextProps | null>(
  null
);

const Provider = ({ children }: ContextPropsChildren): JSX.Element => {
  const [jsonFireBaseData, setJsonFireBaseData] = useState<any>({});
  const [jsonInstagramData, setJsonInstagramData] = useState({});
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [showLogo, setShowLogo] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let feed =
    location.pathname.includes("feed") || location.pathname.includes("/");

  let currentLocation: string = "";

  if (sessionStorage.getItem("product"))
    currentLocation = location.pathname.includes("/") ? location.pathname : "";

  useEffect(() => {
    if (location.search || sessionStorage.getItem("product")) {
      sessionStorage.setItem("product", "onlyfans");

      const [data] = Object.entries(sessionStorage).filter(
        (x) => x[0] === "product"
      );

      navigate(location.pathname + `?${data[0]}=${data[1]}`);
      setShowLogo(true);
    }
  }, [currentLocation]);

  useEffect(() => {
    // React advises to declare the async function directly inside useEffect

    const firebaseData = async () => {
      try {
        const response = await fetch(
          `https://${process.env.REACT_APP_FIREBASE_URL}/data.json`
        );

        const data = await response.json();

        setJsonFireBaseData(data);
      } catch (e) {
        console.error("No firebase data available! " + e);
      }
    };

    const instagramData = async () => {
      try {
        const response = await fetch(
          `https://graph.facebook.com/v16.0/${process.env.REACT_APP_INSTAGRAM_BUSINESS_USER_ID}/media?access_token=${process.env.REACT_APP_LONG_LIVED_TOKEN}`
        );

        const { data } = await response.json();

        const imageIds = data.map(({ id }: { id: string }) => {
          return id;
        });

        const Images = imageIds.map(async (id: string) => {
          const response = await fetch(
            `https://graph.facebook.com/${id}?fields=id,username,owner,media_type,media_url,caption,like_count,comments_count&access_token=${process.env.REACT_APP_LONG_LIVED_TOKEN}`
          );

          return response.json();
        });

        const Comments = imageIds.map(async (id: string) => {
          const response = await fetch(
            `https://graph.facebook.com/v16.0/${id}/comments?fields=from,text&access_token=${process.env.REACT_APP_LONG_LIVED_TOKEN}`
          );

          return response.json();
        });

        const allCommentsData = await Promise.all(Comments).then(
          (results) => results
        );

        const allImageData = await Promise.all(Images).then(
          (results) => results
        );

        const allFeedData = allImageData.map((item, index) => ({
          ...item,
          ...(allCommentsData[index].data.length > 0 && allCommentsData[index]),
        }));

        setJsonInstagramData({ allFeedData });
      } catch (e) {
        console.error("No instagram data available! " + e);
      }
    };

    firebaseData();

    if (feed) {
      instagramData();
    }

    setLoading(false);
  }, [feed]);

  return (
    <GlobalDataContext.Provider
      value={{
        jsonFireBaseData,
        jsonInstagramData,
        loading,
        show,
        setShow: (bool: boolean) => setShow(bool),
        showLogo,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export default Provider;
