import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../src/theme";

function Theme({ children }) {
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  let navHeight = theme.navHeightDesktop;
  let sectionPadding = theme.sectionPadding;

  if (isMd) {
    sectionPadding = "padding-bottom: 2.5rem; padding-top: 2.5rem";
  } else {
    navHeight = theme.navHeightMobile;
  }

  return (
    <ThemeProvider theme={{ ...theme, navHeight, sectionPadding }}>
      {children}
    </ThemeProvider>
  );
}

export default Theme;
