import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  navHeightDesktop: "80px",
  navHeightMobile: "50px",
  sectionPadding: "padding-bottom: 2.5em;",
  fonts: {
    primary: "Roboto, sans-serif",
    secondary: "Roboto, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#000",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#fff",
      light: "#FAFAFA",
    },
    tertiary: {
      main: "#1F1E2C",
    },
    text: {
      primary: "#fff",
      secondary: "#fff",
      tertiary: "#FD5A0D",
      content: "#848484",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#eee",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Roboto", "Open Sans", "Arial", sans-serif`,
  },
  shadows: Array(25).fill("none"),
  transition: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
});

export default theme;
