import React from "react";
import * as S from "./custom-bg-image.styles";

export interface CustomBgImageProps {
  className?: string;
  image: string;
  video: string;
  tag: string;
  fallback: string;
  children: JSX.Element | JSX.Element[];
  [otherProps: string | number | symbol]: unknown;
}

const CustomBgImage = React.memo(function Image(props: CustomBgImageProps) {
  const { className, image, video, tag, fallback, children, ...otherProps } =
    props;

  return (
    <S.Wrapper className={className} {...otherProps}>
      {image ? (
        <div className="gatsby-image-wrapper">
          <img src={image} role="presentation" />
        </div>
      ) : (
        <div className="gatsby-image-wrapper">
          <video src={video} autoPlay muted loop playsInline />
        </div>
      )}
      {children}
    </S.Wrapper>
  );
});

export default CustomBgImage;
