// @ts-nocheck

import { styled } from "@mui/system";
import { Box, Container, Grid, NativeSelect } from "@mui/material";
import CustomLink from "../custom-link/custom-link.component";

export const Background = styled(Box)`
  position: fixed;
  z-index: 10;
  height: ${({ theme }) => theme.navHeight};
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0 7px 4px -2px rgba(0, 0, 0, 0.1);
`;

export const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  height: calc(100% - 30px);
`;

export const BackgroundBar = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const WrapperBar = styled(Grid)`
  justify-content: flex-end;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const Email = styled(Grid)`
  display: flex;
  align-items: center;
  margin-right: 1em;
`;

export const Phone = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const Text = styled("a")`
  margin-left: 0.5em;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  line-height: ${({ theme }) => theme.typography.pxToRem(16)};
`;

export const InnerWrapper = styled(Grid)`
  //margin-top: 2em;
`;

export const GridLogo = styled(Grid)`
  img {
    max-width: 220px;
  }
`;

export const LogoGridOnly = styled(Grid)`
  align-items: "center";
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;

  img {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const CustomGrid = styled(Grid)`
  max-width: 920px;
  margin: auto 0 auto auto;

  ${({ theme }) => theme.breakpoints.up("md")} {
    position: relative;
    right: -10px;
  }
`;

export const GridWrapper = styled("div")`
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Dropdown = styled("div")`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  box-shadow: 0 7px 4px -2px rgba(0, 0, 0, 0.1);

  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    li {
      list-style: unset;
    }
  }
`;

export const GridContainer = styled(Grid)`
  max-width: 1140px;
  margin: auto;
`;

export const GridInnerContainer = styled(Grid)`
  max-width: 285px;

  &:last-child {
  }

  &:nth-child(2) {
    margin: auto;
  }

  &:last-child {
    margin: auto 0 auto auto;
  }
`;

export const SubMenuWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive }) => isActive && ` background-color: #fd5a0d;`}

  a {
    ${({ isActive }) => isActive && `color: #ffffff;`}
  }

  &:hover {
    background-color: #fd5a0d;

    a {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;

export const SubLink = styled(CustomLink)`
  display: block;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};

  &.active,
  &:hover {
    text-decoration: underline;
    text-decoration-color: #fd5a0d;
    text-decoration-thickness: 0.3em !important;
    text-underline-offset: ${({ theme }) =>
      theme.typography.pxToRem(21)} !important;
  }
`;

export const DropdownWrapper = styled("div")`
  position: absolute;
  top: 160px;
  left: 0;
  width: 100%;

  a {
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    line-height: ${({ theme }) => theme.typography.pxToRem(15)};

    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
`;

export const GridContent = styled(Grid)`
  max-width: 1140px;
  margin: auto;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const GridImage = styled(Grid)`
  cursor: pointer;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const GridSubItems = styled(Grid)`
  margin: auto;
`;

export const InnerWrapperItems = styled(Grid)`
  padding-right: 2em;
  padding-left: 2em;

  a {
    @media (max-width: 1019.98px) and (min-width: 960px) {
      font-size: ${({ theme }) => theme.typography.pxToRem(12)};
    }
  }
`;

export const SubMenuItem = styled("a")`
  &.active,
  &:hover {
    text-decoration: underline;
    text-decoration-color: #fd5a0d;
    text-decoration-thickness: 0.2em;
    text-underline-offset: ${({ theme }) => theme.typography.pxToRem(4)};
  }
`;

export const Item = styled("li")`
  text-align: center;
  list-style: none;
  text-transform: uppercase;
  margin: 1em;

  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 500;
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    line-height: ${({ theme }) => theme.typography.pxToRem(16)};

    &:hover,
    &.active {
      font-weight: 600;
      text-decoration: underline;
      text-decoration-color: #fd5a0d;
      text-decoration-thickness: 0.2em;
      text-underline-offset: ${({ theme }) => theme.typography.pxToRem(17)};
    }
  }
`;

export const CustomDropdown = styled<{ theme: any; div }>(NativeSelect)`
  border: unset;

  &:focus {
    background-color: unset;
  }

  &:before,
  &:after {
    border: unset;
    content: unset;
  }

  select {
    margin: 0.5em 0;
    padding: 0px 3px 0px 6px;
    color: ${({ theme }) => theme.palette.text.tertiary};
    border: 1px solid #fafafa;
    font-size: ${({ theme }) => theme.typography.pxToRem(12)};
    line-height: ${({ theme }) => theme.typography.pxToRem(16)};
  }

  svg {
    top: calc(80% - 12px);
    right: 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.text.tertiary};
  }
`;
