import { styled } from "@mui/system";

export const Wrapper = styled("div")`
  text-align: center;
  padding: 0rem 2rem 2rem;

  h2 {
    font-size: 60px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      font-size: 32px;
    }
  }

  p {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 28px;
    margin-top: 0.5rem;

    .hide {
      visibility: hidden;
    }

    .show {
      visibility: visible;
      //-webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      //animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
  }

  @keyframes slide-right {
    0% {
      display: block;
    }
    50% {
      display: none;
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
    75% {
      display: none;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      display: block;
    }
  }

  div:nth-of-type(2) {
    margin: auto auto 2rem auto;
    max-width: 600px;
    max-height: 600px;
  }

  button {
    margin-bottom: 2rem;
  }
`;
