import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const Main = styled("main")``;

export const GridContainer = styled(Grid)`
  text-align: center;
  height: 50vh;

  img {
    max-width: 100%;
  }

  div {
    margin: auto;

    div:first-child img {
      width: 200;
      height: 200px;

      &:first-child {
        margin-bottom: 2rem;
      }
    }
  }
`;
