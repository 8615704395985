import { styled } from "@mui/system";

export const Dash = styled("div")`
  background-color: ${({ color }) => color};
  height: 7px;
  width: ${({ wd }) => wd + "px"};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 2px;
    width: ${({ wd }) => Math.ceil(wd * 0.7) + "px"};
  }
`;
