import React from "react";
import * as S from "./social-media.styles";

import Grid from "@mui/material/Grid";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";

import { GlobalDataContext } from "../../context/provider";
import { GetContext } from "../../utils/utils";

const SocialMedia = ({ data }: any) => {
  const { showLogo } = GetContext(GlobalDataContext);

  return (
    <Grid item xs={12} md={2}>
      <S.InnerWrapper>
        {data && data.follow.length > 0 && (
          <S.Text>{data.follow[0].title}</S.Text>
        )}
        <S.Flex showLogo={showLogo}>
          <a href={data.social.instagram} target="_blank">
            <Instagram />
          </a>
          {/* <a href={data.social.twitter} target="_blank">
            <Twitter />
          </a> */}
          {/* <a href={data.social.tiktok} target="_blank">
            <Tiktok />
          </a> */}
          {showLogo && (
            <a href={data.social.telegram} target="_blank">
              <Telegram />
            </a>
          )}
        </S.Flex>
      </S.InnerWrapper>
    </Grid>
  );
};

export default SocialMedia;
