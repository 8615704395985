import React, { useEffect, useState } from "react";
import * as S from "./instagram-feed.styles.jsx";

import { Box, Skeleton, Tab } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <S.Panel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </S.Panel>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ITEMS_PER_PAGE = 3;

const InstagramFeed = ({ data }) => {
  const { jsonInstagramData, loading } = data;
  let { allFeedData } = jsonInstagramData;
  const [feed, setFeed] = useState({});
  const [images, setImages] = useState({});
  const [videos, setVideos] = useState({});

  const [items, setItems] = useState(ITEMS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1);
    newValue === 0
      ? setTotalPages(Math.ceil(images?.length / ITEMS_PER_PAGE))
      : setTotalPages(Math.ceil(videos?.length / ITEMS_PER_PAGE));
  };

  useEffect(() => {
    // if (allFeedData) {
    //   const timer = window.setTimeout(() => {
    //     setFeed(onPageLoad());
    //   }, 3000);
    //   return () => window.clearTimeout(timer);
    // } else {
    //   const getData = async () => {
    //     const response = await fetch("/feed")
    //       .then((feed) => {
    //         return feed.json(); // JSON data parsed by `data.json()` call
    //       })
    //       .catch((e) => {
    //         console.error("The instagram request failed" + e);
    //       });

    //     setFeed(JSON.parse(response));
    //   };

    //   getData();
    // }

    setFeed(allFeedData);

    const imageType = allFeedData?.filter(
      ({ media_type }) => media_type !== "VIDEO"
    );
    const videoType = allFeedData?.filter(
      ({ media_type }) => media_type === "VIDEO"
    );

    setImages(imageType);
    setVideos(videoType);

    setTotalPages(Math.ceil(allFeedData?.length / ITEMS_PER_PAGE) - 1 || 1);
  }, [data, loading]);

  const onPageLoad = () => {
    postData("/feed", { allFeedData })
      .then((feed) => {
        console.log(feed); // JSON data parsed by `data.json()` call
      })
      .catch((e) => {
        console.error("The instagram request failed" + e);
      });
  };

  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    return response.json();
    // parses JSON response into native JavaScript objects
  }

  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height="100%"></Skeleton>
      ) : (
        <Box sx={{ width: "100%" }}>
          <S.Wrapper>
            <S.TabsWrapper
              value={value}
              onChange={handleChange}
              aria-label="instagram news"
            >
              <Tab label="Publicaciones" {...a11yProps(0)} />
              <Tab label="Videos" {...a11yProps(1)} />
            </S.TabsWrapper>
          </S.Wrapper>
          <TabPanel value={value} index={0}>
            {images?.length > 0 &&
              images
                .slice(currentPage * items - items, currentPage * items)
                .map((item, index) => (
                  <S.ContainerWrapper key={`instagram-image-${index}`}>
                    <S.BoxWrapper>
                      <S.BoxInnerWrapper
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        <S.Left>
                          <img src={item.media_url} />
                        </S.Left>
                        {item?.data?.length > 0 ? (
                          <S.Right>
                            {item?.caption && (
                              <S.Flex>
                                <S.UserName
                                  href={`https://www.instagram.com/${item.username}/`}
                                  target="_blank"
                                >
                                  {item.username}
                                </S.UserName>
                                <S.Comment>{item.caption}</S.Comment>
                              </S.Flex>
                            )}
                            {item?.data?.length > 0 &&
                              item?.data.slice(0, 3).map((subItem, index) => (
                                <S.Flex key={`user-${index}`}>
                                  <S.UserName
                                    href={`https://www.instagram.com/${subItem.from.username}/`}
                                    target="_blank"
                                  >
                                    {subItem.from.username}
                                  </S.UserName>
                                  <S.Comment>{subItem.text}</S.Comment>
                                </S.Flex>
                              ))}
                          </S.Right>
                        ) : (
                          <S.Right>
                            <S.Comment>No existen comentarios...</S.Comment>
                          </S.Right>
                        )}
                      </S.BoxInnerWrapper>
                      <S.Icon
                        href={`https://www.instagram.com/yomiko.dolly/`}
                        target="_blank"
                      >
                        <span>Continuar a Instagram</span> <ArrowForwardIcon />
                      </S.Icon>
                    </S.BoxWrapper>
                  </S.ContainerWrapper>
                ))}
            <S.Page
              count={totalPages}
              onChange={(event, value) => setCurrentPage(value)}
              variant="outlined"
              color="secondary"
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {videos?.length > 0 &&
              videos
                .slice(currentPage * items - items, currentPage * items)
                .map((item, index) => (
                  <S.ContainerWrapper key={`instagram-image-${index}`}>
                    <S.BoxWrapper>
                      <S.BoxInnerWrapper
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        <S.Left item xs={4}>
                          <video
                            src={item.media_url}
                            key={`instagram-video-${index}`}
                            autoPlay
                            controls
                            loop
                          />
                        </S.Left>
                        {item?.data?.length > 0 ? (
                          <S.Right item xs={8}>
                            {item?.caption && (
                              <S.Flex>
                                <S.UserName
                                  href={`https://www.instagram.com/${item.username}/`}
                                  target="_blank"
                                >
                                  {item.username}
                                </S.UserName>
                                <S.Comment>{item.caption}</S.Comment>
                              </S.Flex>
                            )}
                            {item?.data?.length > 0 &&
                              item?.data.slice(0, 3).map((subItem, index) => (
                                <S.Flex>
                                  <S.UserName
                                    href={`https://www.instagram.com/${subItem.from.username}/`}
                                    target="_blank"
                                  >
                                    {subItem.from.username}
                                  </S.UserName>
                                  <S.Comment>{subItem.text}</S.Comment>
                                </S.Flex>
                              ))}
                          </S.Right>
                        ) : (
                          <S.Right item xs={8}>
                            <S.Comment>No existen comentarios...</S.Comment>
                          </S.Right>
                        )}
                        <S.Icon
                          href={`https://www.instagram.com/yomiko.dolly/`}
                          target="_blank"
                        >
                          <span>Continuar a Instagram</span>{" "}
                          <ArrowForwardIcon />
                        </S.Icon>
                      </S.BoxInnerWrapper>
                    </S.BoxWrapper>
                  </S.ContainerWrapper>
                ))}
            <S.Page
              count={totalPages}
              onChange={(event, value) => setCurrentPage(value)}
              variant="outlined"
              color="secondary"
            />
          </TabPanel>
        </Box>
      )}
    </>
  );
};

export default InstagramFeed;
