import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";

export const PopupDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const Content = styled(DialogContent)`
  text-align: center;

  h2 {
    color: #000000;
  }

  p {
    color: #000000;
  }

  img:first-child {
    margin-bottom: 1rem;
  }

  img {
    height: auto;
    max-width: 180px;
  }
`;

export const GridContainer = styled(Grid)`
  a {
    text-decoration: none;
  }

  div div:first-child img {
    width: 160px;
    height: 160px;
  }
`;
