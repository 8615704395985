import React, { useEffect, useRef, useState } from "react";
import { Wheel } from "react-custom-roulette";

import { GlobalDataContext } from "../../context/provider";
import { GetContext } from "../../utils/utils";

import { Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import GeneralHero from "../../layouts/general-hero/general-hero.component";
import * as S from "./slot-game.styles";

const SlotGame = () => {
  const { jsonFireBaseData } = GetContext(GlobalDataContext);

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [data, setData] = useState<any>([{}]);

  const generateDataRandomizer = (): {}[] => {
    let getData: {}[] = [];
    for (let i = 0; i < 77; i++) {
      const randomColor =
        "#" + Math.floor(Math.random() * 16777215).toString(16);
      getData.push({ option: i, style: { backgroundColor: randomColor } });
    }

    return getData;
  };

  useEffect(() => {
    let getData: {}[] = generateDataRandomizer();
    setData(getData);
  }, []);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber: number = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);

      animateNumber.current!.classList.add("hide");
    }
  };

  const handleStopSpinning = () => {
    animateNumber.current!.classList.remove("hide");
    animateNumber.current!.classList.add("show");
  };

  const animateNumber = useRef<HTMLDivElement>(null);

  if (!jsonFireBaseData) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>Bingo de Yomiko Dolly!</title>
      </Helmet>
      <GeneralHero
        data={{ jsonFireBaseData }}
        pageName={"Bingo de Yomiko Dolly"}
      />
      <S.Wrapper>
        <div>
          <h2>Bienvenidos a mi Juego!</h2>
          <p>El número ganador es:</p>
          <p>
            <span ref={animateNumber}>
              {prizeNumber !== 0 ? prizeNumber : "???"}
            </span>
          </p>
        </div>
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          onStopSpinning={() => {
            setMustSpin(false);
            handleStopSpinning();
          }}
          radiusLineWidth={0}
          fontSize={14}
          textDistance={90}
          spinDuration={0.75}
          innerRadius={30}
          innerBorderWidth={5}
        />
        <Button variant="contained" onClick={handleSpinClick}>
          Iniciar
        </Button>
      </S.Wrapper>
    </>
  );
};

export default SlotGame;
