import { styled } from "@mui/system";
import { Grid } from "@mui/material";

export const Wrapper = styled("footer")`
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 3em 0 1em;
  background-color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 2em;
  }

  a {
    color: #89cff0;
  }
`;

export const TopSection = styled("div")`
  border-bottom: 1px solid rgba(250, 250, 250, 0.25);
`;

export const GridContainer = styled(Grid)`
  margin-bottom: 1rem;
`;

export const LogoGrid = styled(Grid)`
  img {
    max-width: 220px;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
`;

export const LogoGridOnly = styled(Grid)`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  div:first-child {
    margin-bottom: 0.5rem;
  }

  a {
    display: inline-block;
  }

  img {
    max-width: 220px;
  }
`;

export const Description = styled("div")`
  p {
    opacity: 0.7;
    margin-top: 0.5em;
    // color: #fafafa;
    font-weight: 500;
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: ${({ theme }) => theme.typography.pxToRem(22)};
  }
`;

export const Text = styled("p")`
  margin-top: 0.5em;
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`;

export const BottomSection = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    flex-direction: column-reverse;
  }
`;

export const Legal = styled("p")`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    text-align: center;
  }
`;

export const Logo = styled("div")`
  display: inline-flex;
  text-align: center;

  a {
    display: inline-flex;

    svg {
      max-width: 130px;
      width: 100%;

      ${({ theme }) => theme.breakpoints.down("xs")} {
        margin-top: 1em;
      }
    }
  }
`;
