import React, { useState } from "react";
import * as S from "./mobile-menu.styles";

import { Fade } from "@mui/material";

import Logo from "../../assets/images/main-logo.png";
import CustomLink from "../custom-link/custom-link.component";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoOnly from "../../assets/images/onlyfans-logo.png";
import footer from "../footer/footer.json";
import SocialMedia from "../social-media/social-media.component";
import { GetContext } from "../../utils/utils";
import { GlobalDataContext } from "../../context/provider";

const MobileMenu = ({ data }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleToggleMenu = () => setIsMenuOpen(!isMenuOpen);

  //const handleChange = (event) => {};

  const { showLogo } = GetContext(GlobalDataContext);

  return (
    <S.Background>
      <S.WrapperMenu>
        <S.Logo>
          <CustomLink url="/">
            <img src={Logo} title="logo" />
          </CustomLink>
        </S.Logo>

        {/* <S.Dropdown>
          <S.CustomDropdown
            value="hi"
            name="language"
            onChange={handleChange}
            IconComponent={ArrowDown}
          >
            <option value="en">ENGLISH</option>
            <option value="es">ESPAÑOL</option>
          </S.CustomDropdown>
        </S.Dropdown> */}
        <S.LogoGridOnly>
          {showLogo && (
            <a href="https://onlyfans.com/yomiko.dolly" target="_blank">
              <img src={LogoOnly} title="logo" />
            </a>
          )}
        </S.LogoGridOnly>
        <S.Button>
          <S.MenuBtn onClick={handleToggleMenu}>
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </S.MenuBtn>
        </S.Button>
        <Fade in={isMenuOpen} mountOnEnter unmountOnExit>
          <S.BackgroundMenu>
            {data &&
              data.menu.length > 0 &&
              data.menu.map((item: any, index: number) =>
                item.url === "" ? (
                  <S.Item key={index} onClick={handleToggleMenu}>
                    <a>{item.title}</a>
                    {data &&
                      data.submenu.length > 0 &&
                      data.submenu.map((subitem: any, index: number) => (
                        <S.SubItem key={`subitem-${index}`}>
                          <CustomLink url={subitem.url}>
                            {subitem.title}
                          </CustomLink>
                        </S.SubItem>
                      ))}
                  </S.Item>
                ) : (
                  <S.Item key={index} onClick={handleToggleMenu}>
                    <CustomLink url={item.url}>{item.title}</CustomLink>
                  </S.Item>
                )
              )}
            <SocialMedia data={footer} />
          </S.BackgroundMenu>
        </Fade>
      </S.WrapperMenu>
    </S.Background>
  );
};

export default MobileMenu;
