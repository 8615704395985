import React, { useState, useEffect } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as S from "./instagram-gallery.styles.jsx";

import { ReactComponent as Comments } from "../../assets/icons/comments-count.svg";
import { ReactComponent as Likes } from "../../assets/icons/likes-count.svg";

const InstagramGallery = ({ data }) => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

  const { jsonInstagramData, loading } = data;
  const { allFeedData } = jsonInstagramData;
  const [images, setImages] = useState({});

  useEffect(() => {
    const imageType = allFeedData?.filter(
      ({ media_type }) => media_type !== "VIDEO"
    );

    setImages(imageType);
  }, [data, loading]);

  return (
    <Box>
      <Grid container>
        {!loading &&
          images?.length > 0 &&
          images.slice(0, matchesMd ? 4 : 6).map((item, index) => (
            <S.Item
              item
              xs={6}
              sm={3}
              md={2}
              key={`instagram-gallery-${index}`}
            >
              <a
                href={`https://www.instagram.com/${item.username}/`}
                target="_blank"
              >
                <div className="animate">
                  <img src={item.media_url} alt="image" />
                </div>
                <S.Wrapper className="wrapper">
                  <div>
                    <div>
                      <Comments />
                      <p>{item.comments_count}</p>
                    </div>
                    <div>
                      <Likes />
                      <p>{item.like_count}</p>
                    </div>
                  </div>
                </S.Wrapper>
              </a>
            </S.Item>
          ))}
      </Grid>
    </Box>
  );
};

export default InstagramGallery;
