import { createGlobalStyle } from "styled-components";

//const pink = "linear-gradient(to bottom, rgba(125,29,52,1), #ed0096)";

// const blue = "linear-gradient(to bottom, #2c3968 33%, #C0C0C0)";

const lightBrown = "#ecd39f";

//FD22E3
const black = "#000";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    /* background-image: ${lightBrown};   */
    background: ${lightBrown};  
  }

  .component-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
`;

export default GlobalStyles;
