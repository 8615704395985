// @ts-nocheck

import React from "react";
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component";
import { GlobalDataContext } from "../../context/provider";
import { GetContext } from "../../utils/utils";

import InstagramFeed from "../../components/instagram-feed/instagram-feed.component.jsx";
import GeneralHero from "../../layouts/general-hero/general-hero.component";

import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const Feed = () => {
  const location = useLocation();
  const pageName = location.pathname.replace(/\//g, "");

  const { jsonFireBaseData, jsonInstagramData, loading } =
    GetContext(GlobalDataContext);

  if (!jsonInstagramData) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>Publicaciones de Yomiko Dolly</title>
      </Helmet>
      <GeneralHero data={{ jsonFireBaseData }} pageName={pageName} />
      <SectionWrapper maxWidth="md">
        <InstagramFeed data={{ jsonInstagramData, loading }}></InstagramFeed>
      </SectionWrapper>
    </>
  );
};

export default Feed;
