import { Container } from "@mui/material";
import { styled } from "@mui/system";
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component";

export const BackgroundImage = styled(CustomBgImage)<any>`
  height: 600px;
  margin-bottom: -0.5rem;
  padding-bottom: 5rem;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    img {
      object-position: 0 -7rem;
    }

    video {
      object-position: 0 -10rem;
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 400px;
    padding-bottom: 2.5rem;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 600px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      height: 400px;
    }
  }

  video {
    display: block;
    margin: auto;
    object-fit: cover;
    width: 100%;
    height: 600px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      width: 100%;
      height: 400px;
    }
  }
`;

export const Wrapper = styled("div")`
  position: relative;
  height: 100%;

  &:before {
    position: absolute;
    left: 0;
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(10, 10, 10, 0.65);
    z-index: -1;
    padding-bottom: 0.025rem;
  }
`;

export const WrapperText = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
`;

export const Title = styled("h1")<any>`
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(30)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    line-height: ${({ theme }) => theme.typography.pxToRem(28)};
  }
`;

export const Text = styled("h2")<any>`
  max-width: 600px;
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: ${({ theme }) => theme.typography.pxToRem(60)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(40)};
    line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  }
`;
