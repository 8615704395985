import Button from "@mui/material/Button";

import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useRef, useState } from "react";
import PayPalQR from "../../assets/images/paypal-qr.png";
import DeUnaQR from "../../assets/images/deuna-qr.png";
import PayPalLogo from "../../assets/images/paypal-logo.png";
import DeUnaLogo from "../../assets/images/deuna-logo.png";
import PichinchaLogo from "../../assets/images/banco-pichincha.png";
// import KoFi from "../../assets/images/kofi_button_blue.png";
import data from "../../locales/en/translations.json";
import * as S from "./popup-dialog.styles";
import { Grid } from "@mui/material";
import { useLocation } from "react-router";

const PopupDialog = () => {
  const [open, setOpen] = useState(false);
  const dataType: any = data;
  const showPopup = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const links: boolean = location.pathname === "/links";
  const honeygain: boolean = location.pathname === "/honeygain";

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    showPopup?.current?.classList.add("display");

    let animate: ReturnType<typeof setTimeout> = setTimeout(() => {
      setOpen(true);
    }, 5000);

    return () => clearTimeout(animate);
  }, []);

  if (links || honeygain) return <></>;

  return (
    <S.PopupDialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      autoFocus
      open={open}
      ref={showPopup}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Modal title
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <S.Content dividers>
        <h2>Apoyame donando!</h2>
        <p>
          Para mejores resultados escanea el codigo QR en las 3 opciones de
          abajo (debes instalar la aplicacion)!
        </p>
        <S.GridContainer container alignItems="center">
          <Grid item md={4} xs={12}>
            <a href={dataType.social["paypal"]}>
              <div>
                <img src={PayPalQR} title="paypal-qr" />
              </div>
              <div>
                <img src={PayPalLogo} title="paypal-logo" />
              </div>
            </a>
          </Grid>
          <Grid item md={4} xs={12} style={{ marginBottom: "1.5rem" }}>
            <p>o directo a mi cuenta 2209190514</p>
            <img src={PichinchaLogo} title="pichincha-logo" />
          </Grid>
          <Grid item md={4} xs={12}>
            <a href={dataType.social["deuna"]}>
              <div>
                <img src={DeUnaQR} title="deuna-qr" />
              </div>
              <div>
                <img src={DeUnaLogo} title="deuna-logo" />
              </div>
            </a>
          </Grid>
        </S.GridContainer>
      </S.Content>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cerrar
        </Button>
      </DialogActions>
    </S.PopupDialog>
  );
};

export default PopupDialog;
