import { styled } from "@mui/system";

export const VideoWrapper = styled("div")`
  margin-top: -2rem;
  margin-bottom: -0.25rem;

  video {
    width: 100%;
    height: 100%;
  }
`;
