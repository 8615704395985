import { styled } from "@mui/system";
import { Swiper } from "swiper/react";

const getBgColor = (color: string, theme: any): any => {
  switch (color) {
    case "main":
    case "light":
    case "dark":
      return `background-color: ${theme.palette.primary[color]}; color: white`;
    case "gray":
      return `background-color: ${theme.palette.secondary.light}`;
    case "secondary":
      return `background-color: ${theme.palette.secondary.main};`;
  }
};

export const Wrapper = styled("div")<any>`
  position: relative;
  // color: ${({ theme }) => theme.palette.text.primary};
  ${({ bg, theme }) => (bg ? getBgColor(bg, theme) : "")};
  text-align: center;

  .height {
    max-height: 600px;

    img {
      max-height: 600px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    z-index: 2;

    svg {
      height: 100px;
      width: 100px;
      background-color: ${({ theme }) => theme.palette.secondary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &:after {
      content: unset;
    }

    ${({ theme }) => theme.breakpoints.down("lg")} {
      position: unset;
      width: 100%;
      height: 50px;

      svg {
        width: 100%;
        height: 50px;
      }
    }
  }
`;

export const Flex = styled("div")`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const Background = styled("div")`
  background-color: ${({ theme }) => theme.palette.primary.main};

  img {
    display: block;
    max-height: 500px;
    margin: auto;
  }
`;

export const SwiperContainer = styled(Swiper)`
  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const Text = styled("h2")`
  margin-bottom: 2rem;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    position: relative;
    top: -6rem;
    margin-bottom: -1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: -3rem;
  }
`;
