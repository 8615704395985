import { styled } from "@mui/system";

export const Main = styled("main")<any>`
  padding-top: ${({ theme, links, honeygain }) =>
    !links && !honeygain && theme.navHeightMobile};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: ${({ theme, links, honeygain }) =>
      !links && !honeygain && theme.navHeightMobile};
  }
`;
