import React, { useEffect, useRef, useState } from "react";
import { HeartSpinner } from "react-spinners-kit";
import * as S from "./spinner.styles.jsx";
import { GlobalDataContext } from "../../context/provider";
import { GetContext } from "../../utils/utils";

const Spinner = () => {
  const { show, setShow } = GetContext(GlobalDataContext);
  const [loading, _] = useState(true);
  const placeLoading = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animate = setTimeout(() => {
      placeLoading?.current?.classList.add("animation");

      setTimeout(() => {
        setShow(false);
      }, 5000);
    }, 1000);

    return () => clearTimeout(animate);
  }, []);

  return (
    <>
      {loading && show && (
        <S.Background ref={placeLoading}>
          <S.Wrapper>
            <HeartSpinner
              className="hearth"
              size={100}
              color="#686769"
              loading={loading}
            />
            <S.Loading>Cargando...</S.Loading>
          </S.Wrapper>
        </S.Background>
      )}
    </>
  );
};

export default Spinner;
