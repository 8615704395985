import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Box, IconButton, NativeSelect } from "@mui/material";

export const Background = styled(Box)`
  display: flex;
  position: fixed;
  z-index: 10;
  height: ${({ theme }: any) => theme.navHeight};
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0 7px 4px -2px rgba(0, 0, 0, 0.1);
`;

export const WrapperMenu = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled(Container)`
  padding-top: 1em;
`;

export const InnerWrapper = styled(Grid)`
  margin-top: 2em;
`;

export const Logo = styled(Grid)`
  padding-left: 1em;

  img {
    max-width: 120px;
  }
`;

export const LogoGridOnly = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 120px;
  }
`;

export const Dropdown = styled(Grid)`
  width: calc(100% - 180px);
  text-align: right;
`;

export const Button = styled(Grid)`
  text-align: right;
`;

export const CustomDropdown = styled(NativeSelect)`
  border: unset;

  &:focus {
    background-color: unset;
  }

  &:before,
  &:after {
    border: unset;
    content: unset;
  }

  select {
    margin: 0.5em;
    padding: 0px 3px 0px 6px;
    color: ${({ theme }) => theme.palette.text.tertiary};
    border: 1px solid #fafafa;
    font-size: ${({ theme }: any) => theme.typography.pxToRem(12)};
    line-height: ${({ theme }: any) => theme.typography.pxToRem(16)};
  }

  svg {
    top: calc(80% - 12px);
    right: 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.text.tertiary};
  }
`;

export const CustomGrid = styled(Grid)`
  max-width: 620px;
  margin: auto 0 auto auto;
`;

export const Item = styled("li")`
  text-align: center;
  list-style: none;
  text-transform: uppercase;
  margin-bottom: 4em;

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.secondary.main};
    font-weight: 600;
    font-size: ${({ theme }: any) => theme.typography.pxToRem(18)};
    line-height: ${({ theme }: any) => theme.typography.pxToRem(25)};

    &:hover,
    &.active {
      font-weight: 600;
      text-decoration: underline;
      text-decoration-color: #fd5a0d;
      text-decoration-thickness: 0.2em;
      text-underline-offset: ${({ theme }: any) => theme.typography.pxToRem(8)};
    }
  }
`;

export const SubItem = styled("div")`
  text-align: center;
  margin: 1em 0;

  a {
    cursor: pointer;
    text-decoration: none;
    opacity: 0.75;
    color: ${({ theme }) => theme.palette.secondary.main};
    font-weight: 500;
    font-size: ${({ theme }: any) => theme.typography.pxToRem(16)};
    line-height: ${({ theme }: any) => theme.typography.pxToRem(22)};

    &:hover,
    &.active {
      opacity: 1;
      font-weight: 600;
      text-decoration: underline;
      text-decoration-color: #fd5a0d;
      text-decoration-thickness: 0.2em;
      text-underline-offset: ${({ theme }: any) => theme.typography.pxToRem(8)};
    }
  }
`;

export const RightItems = styled("div")`
  display: flex;
  margin-left: auto;
`;

export const MenuBtn = styled(IconButton)`
  height: 50px;
  width: 50px;
  background-color: #fd5a0d;
  border: 1px solid #ffffff;
  padding: 0.15em;
  border-radius: 0;
  border: unset;

  .MuiSvgIcon-root {
    fill: #ffffff;
    height: 2em;
    width: 2em;
  }

  &:hover {
    background-color: #fd5a0d;
  }
`;

export const Description = styled("div")`
  text-align: center;
  color: #ffffff;

  h2 {
    margin-bottom: 0.5em;
    font-size: ${({ theme }: any) => theme.typography.pxToRem(18)};
  }

  .phone {
    display: block;
    margin-top: 0;
    margin-bottom: 1em;
    color: #ffffff;
    text-decoration: none;
    font-weight: 400;
    font-size: ${({ theme }: any) => theme.typography.pxToRem(24)};
  }

  .address {
    margin-bottom: 0.5em;
    font-size: ${({ theme }: any) => theme.typography.pxToRem(14)};
    opacity: 0.75;

    span {
      font-weight: 500;
    }
  }

  .social-media {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #ffffff;
    font-size: ${({ theme }: any) => theme.typography.pxToRem(18)};
    opacity: 0.75;

    p {
      margin-right: 0.5em;
    }
  }
`;

export const BackgroundMenu = styled("div")`
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 80vw;
  padding: 2em 1em;
  background-color: #1f1e2c;
`;
