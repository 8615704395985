import React from "react";
import * as S from "./section-wrapper.styles";
import Container from "@mui/material/Container";

type SectionWrapperProps = {
  children: JSX.Element | JSX.Element[];
  bg?: any;
  maxWidth: any;
  className?: string;
};

const SectionWrapper = ({
  children,
  bg,
  maxWidth = false,
  className,
}: SectionWrapperProps) => (
  <S.Container className={className} bg={bg}>
    {maxWidth ? (
      <Container maxWidth={maxWidth}>{children}</Container>
    ) : (
      children
    )}
  </S.Container>
);

export default SectionWrapper;
