import { styled } from "@mui/system";

const getBgColor = (color: string, theme: any) => {
  switch (color) {
    case "main":
    case "light":
    case "dark":
      return `background-color: ${theme.palette.primary[color]}; color: white`;
    case "gray":
      return `background-color: ${theme.palette.secondary.light}`;
    case "secondary":
      return `background-color: ${theme.palette.secondary.main};`;
  }
};

export const Container = styled("section")<any>`
  position: relative;
  ${({ theme }) => theme.sectionPadding};
  // color: ${({ theme }) => theme.palette.text.primary};
  ${({ bg, theme }) => (bg ? getBgColor(bg, theme) : "")};
`;
