import React from "react";

import { GlobalDataContext } from "../../context/provider";
import { GetContext } from "../../utils/utils";
import Video from "../../assets/videos/i-love-you.mp4";

import * as S from "./my-love.styles"

import { Helmet } from "react-helmet-async";

const MyLove = () => {
  const { jsonFireBaseData } = GetContext(GlobalDataContext);

  if (!jsonFireBaseData) {
    return;
  }

  return (
    <>
      <Helmet>
        <title>Donaciones, Yomiko Dolly!</title>
      </Helmet>
      <S.VideoWrapper>
        <video src={Video} autoPlay muted loop playsInline />
      </S.VideoWrapper>
    </>
  );
};

export default MyLove;
