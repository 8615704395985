import React from "react";

import { Helmet } from "react-helmet-async";
import * as S from "./links.styles";
// import image from "../../assets/images/links.jpg";
import image from "../../assets/images/links.jpg";
import video from "../../assets/videos/sanrio.mp4";
import { CustomButton } from "../../components/custom-button/custom-button.styles";
import data from "../../components/footer/footer.json";

const Links = () => {
  if (!image && !video) return <></>;

  return (
    <>
      <Helmet>
        <title>Links Yomiko Dolly!</title>
      </Helmet>
      <S.BackgroundImage image="https://storage.googleapis.com/yomiloops_database/links-image-2.jpeg">
        <S.Wrapper>
          <S.InnerWrapper maxWidth="sm">
            <S.VideoWrapper image="https://storage.googleapis.com/yomiloops_database/links-image-2.jpeg">
              <S.Shadow>
                <S.PaddingWrapper>
                  <S.Title>Hola, soy Yomiko Dolly!</S.Title>
                  <S.Text>Sigueme o escribeme en:</S.Text>
                  <S.ButtonWrapper>
                    {/* <CustomButton href={data.social.free}>
                      Foto Gratis
                    </CustomButton> */}
                    <CustomButton href={data.social.tiktok} target="_blank">
                      TikTok
                    </CustomButton>
                    <CustomButton href={data.social.instagram} target="_blank">
                      Instagram
                    </CustomButton>
                    <CustomButton href={data.social.telegram} target="_blank">
                      Telegram
                    </CustomButton>
                    <CustomButton href={data.social.onlyfans} target="_blank">
                      OnlyFans
                    </CustomButton>
                  </S.ButtonWrapper>
                </S.PaddingWrapper>
              </S.Shadow>
            </S.VideoWrapper>
          </S.InnerWrapper>
        </S.Wrapper>
      </S.BackgroundImage>
    </>
  );
};

export default Links;
