import React from "react";

import { GlobalDataContext } from "../../context/provider";
//import GeneralHero from "../../layouts/general-hero/general-hero.component";
import { GetContext } from "../../utils/utils";
import HoneygainQR from "../../assets/images/qr-honeygain.png";
// import HonegainAPK from "../../assets/apk/honeygain_app.apk";
import PasoUno from "../../assets/images/pasos.png";

import data from "../../locales/en/translations.json";

import * as S from "./honeygain.styles";

import { Helmet } from "react-helmet-async";

const Honeygain = () => {
  const { jsonFireBaseData } = GetContext(GlobalDataContext);

  if (!jsonFireBaseData) {
    return;
  }

  const dataType: any = data;

  return (
    <>
      <Helmet>
        <title>Honeygain, Yomiko Dolly!</title>
      </Helmet>
      <S.Wrapper maxWidth="md">
        <h2 style={{ textAlign: "center", marginTop: "0", paddingTop: "4rem" }}>
          Para tener una foto mia gratis da click en el link e inscribete en la
          app. Ganaras $3 gratis. Al terminar de instalar me notificas y reviso
          para enviarte la foto.
        </h2>
        <S.InnerWrapper>
          <img src={HoneygainQR} title="honeygain-qr" />
          <p>
            Usa mi link{" "}
            <a href={dataType.social.honeygain}>
              https://r.honeygain.me/SADFA89ACD
            </a>{" "}
            y descarga la app
          </p>
        </S.InnerWrapper>
        <p style={{ textAlign: "center" }}>Pasos</p>
        <S.List>
          <li>Escanea el QR o da click en "Descarga la app"</li>
          <li>Da click en el boton azul "Reclamar $3"</li>
          <img src={PasoUno} alt="paso" />
          <li>Registrate facilmente con google</li>
          <li>
            Descarga la apk de la app{" "}
            <a
              download
              href="https://drive.google.com/file/d/1KrS7_8ipK8cPkKemQqHSyW_4HW6ebbHX/view?usp=sharing"
            >
              aqui
            </a>
          </li>
          <li>
            Habilita la instalacion de aplicaciones de fuentes desconocidas
          </li>
          <li>
            Habre la app, anda a mas en la esquina derecha inferior, logueate y
            gana dinero!
          </li>
        </S.List>
      </S.Wrapper>
    </>
  );
};

export default Honeygain;
