import { styled } from "@mui/system";
import { Grid } from "@mui/material";

export const Item = styled(Grid)`
  position: relative;
  background-color: black;

  .animate {
    overflow: hidden;

    img {
      transition: transform 0.5s;
      object-fit: cover;
      width: 100%;
      height: 200px;
    }
  }

  .wrapper {
    visibility: hidden;
    transition: visibility 0.25s;
  }

  &:hover {
    .animate img {
      transform: scale(1.1);
    }

    .wrapper {
      visibility: visible;
    }
  }
`;

export const Wrapper = styled("div")`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

  > div {
    display: flex;
    justify-content: center;
    height: 100%;
    text-align: center;

    div {
      margin: auto 1rem;

      svg {
        height: 100%;
        width: 30px;
        margin-bottom: 0.25rem;
      }

      p {
        margin-top: 0.25rem;
        margin-bottom: 0;
      }
    }
  }
`;
