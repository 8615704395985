import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface CustomLinkProps {
  url: string;
  target?: string;
  children: JSX.Element | JSX.Element[] | ReactNode;
  className?: string;
}

const CustomLink = ({ url, target, children, className }: CustomLinkProps) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(url);
  const file = /\.[0-9a-z]+$/i.test(url);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    if (file) {
      return (
        <Link to={url} className={className} target={target}>
          {children}
        </Link>
      );
    }
  }

  // <S.CustomAniLink
  //   fade to={url}
  //>
  //   {children}
  // </S.CustomAniLink

  return (
    <Link className={className} to={url} target={target}>
      {children}
    </Link>
  );
};

export default CustomLink;
