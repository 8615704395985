import React from "react";

import moment from "moment/moment";
import data from "./footer.json";
import * as S from "./footer.styles";

import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/images/main-logo.png";
import LogoOnly from "../../assets/images/onlyfans-logo.png";
// import KoFi from "../../assets/images/kofi_button_blue.png";
import CustomLink from "../custom-link/custom-link.component";
import SocialMedia from "../social-media/social-media.component";
import dataTwo from "../../locales/en/translations.json";

import { GlobalDataContext } from "../../context/provider";
import { GetContext } from "../../utils/utils";
import InstagramGallery from "../instagram-gallery/instagram-gallery.component";

const Footer = () => {
  let location = useLocation();
  let feed = location.pathname.includes("feed");
  const dataType: any = dataTwo;

  const { jsonInstagramData, loading, showLogo } =
    GetContext(GlobalDataContext);

  return (
    <>
      {!feed && <InstagramGallery data={{ jsonInstagramData, loading }} />}
      <S.Wrapper>
        <Container maxWidth="lg">
          <S.TopSection>
            <S.GridContainer container>
              <S.LogoGrid item xs={12} md={2}>
                <CustomLink url="/">
                  <img src={Logo} title="logo" />
                </CustomLink>
              </S.LogoGrid>
              {/* <FooterItems data={data} /> */}
              <S.LogoGridOnly item xs={12} md={8}>
                {showLogo && (
                  <div>
                    <a href={dataType.social["only-fans"]} target="_blank">
                      <img src={LogoOnly} title="logo" />
                    </a>
                  </div>
                )}
                {/* <div>
                  <a href={dataType.social["ko-fi"]} target="_blank">
                    <img src={KoFi} title="logo" />
                  </a>
                </div> */}
              </S.LogoGridOnly>
              <SocialMedia data={data} />
            </S.GridContainer>
          </S.TopSection>
          <S.BottomSection>
            <S.Legal>
              {`© ${moment().format("YYYY")}, Made by `}
              <a href="https://www.instagram.com/ealexismt" target="_blank">
                Eddy Topete
              </a>
              {`. All rights reserved.`}
            </S.Legal>
          </S.BottomSection>
        </Container>
      </S.Wrapper>
    </>
  );
};

export default Footer;
