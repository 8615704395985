import { Box, Pagination, Tabs } from "@mui/material";
import { styled } from "@mui/system";

export const Wrapper = styled(Box)`
  margin-bottom: 2rem;
`;

export const TabsWrapper = styled(Tabs)`
  .MuiTabs-indicator {
    height: 4px;
    background-color: #fff;
  }

  .Mui-selected {
    color: #fff !important;
  }
`;

export const Panel = styled("div")`
  background-color: black;

  img,
  video {
    object-fit: contain;
    display: block;
    margin: auto;
    width: 100%;
    height: 200px;
  }
`;

export const Page = styled(Pagination)`
  padding-top: 1rem;
  padding-bottom: 1rem;

  ul {
    justify-content: center;
  }
`;

export const ContainerWrapper = styled("div")``;

export const BoxWrapper = styled("div")`
  padding: 3rem 2rem 1rem;
  border-bottom: 1px solid #fff;
`;

export const BoxInnerWrapper = styled(Box)`
  margin-right: -1rem;
  margin-left: -1rem;
  padding-bottom: 1rem;

  > div {
    box-sizing: border-box;
  }
`;

export const Left = styled("div")`
  flex: 0 0 45%;
  max-width: 45%;
  padding-right: 1rem;
  padding-left: 1rem;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }
`;

export const Right = styled("div")`
  flex: 0 0 55%;
  max-width: 55%;
  padding-right: 1rem;
  padding-left: 1rem;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const Flex = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

export const UserName = styled("a")`
  margin-right: 0.5rem;
  color: #ccc;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Comment = styled("p")`
  margin-top: 0;
  margin-bottom: 0.25rem;
`;

export const Icon = styled("a")`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  color: #fff;
`;
