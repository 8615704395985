import React from "react";
import * as S from "./general-hero.styles";
import Dash from "../../components/dash/dash.component";

interface GeneralHeroProps {
  data: {
    jsonFireBaseData: {
      generalHero: {
        image: string;
        video: string;
        text: string;
        title: string;
      };
    };
  };
  pageName?: string;
}

const GeneralHero = ({ data, pageName }: GeneralHeroProps): JSX.Element => {
  const { jsonFireBaseData } = data;
  const { image, video, text, title } = jsonFireBaseData.generalHero ?? {};

  if (!image && !video) return <></>;

  return (
    <S.BackgroundImage image={image} video={video}>
      <S.Wrapper>
        <S.WrapperText maxWidth="lg">
          <S.Title>{pageName ?? title}</S.Title>
          <S.Text>{text}</S.Text>
          <Dash wd={96} color="#ffffff" />
        </S.WrapperText>
      </S.Wrapper>
    </S.BackgroundImage>
  );
};

export default GeneralHero;
