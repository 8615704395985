import { Container, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import * as S from "./header.styles";

import Logo from "../../assets/images/main-logo.png";
import LogoOnly from "../../assets/images/onlyfans-logo.png";
// import KoFi from "../../assets/images/kofi_button_blue.png";
import data from "../../locales/en/translations.json";
import CustomLink from "../custom-link/custom-link.component";
import MobileMenu from "../mobile-menu/mobile-menu.component";
import { GetContext } from "../../utils/utils";
import { GlobalDataContext } from "../../context/provider";

const Header = (): JSX.Element => {
  const [show, setShow] = useState(false);

  const showMenu = () => {
    setShow(true);
  };

  const hideMenu = () => {
    setShow(false);
  };

  const { showLogo } = GetContext(GlobalDataContext);
  const dataType: any = data;
  //const handleChange = (event) => {};

  return (
    <header>
      <Paper sx={{ display: { md: "block", xs: "none" } }}>
        <S.Background>
          <S.BackgroundBar>
            <Container maxWidth="lg">
              <S.WrapperBar container>
                {/* <S.Email item>
                  <Email />
                  <S.Text href="mailto:Customer.Service@flexmembranes.com">
                    Customer.Service@flexmembranes.com
                  </S.Text>
                </S.Email>
                <S.Phone item>
                  <Phone />
                  <S.Text href="tel:(610)916-9500">(610) 916-9500</S.Text>
                </S.Phone> */}
              </S.WrapperBar>
            </Container>
          </S.BackgroundBar>
          <S.Wrapper>
            <S.InnerWrapper
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ textAlign: "center" }}
            >
              <S.GridLogo item xs={12} md={3}>
                <CustomLink url="/">
                  <img src={Logo} title="logo" />
                </CustomLink>
              </S.GridLogo>
              <Grid item xs={12} md={6}>
                {showLogo && (
                  <S.LogoGridOnly
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12} md={6}>
                      <a href={dataType.social["only-fans"]} target="_blank">
                        <img src={LogoOnly} title="logo" />
                      </a>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                    <a href={dataType.social["ko-fi"]} target="_blank">
                      <img src={KoFi} title="logo" />
                    </a>
                  </Grid> */}
                  </S.LogoGridOnly>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <S.CustomGrid>
                  <S.GridWrapper>
                    {data &&
                      data.menu.length > 0 &&
                      data.menu.map((item, index) =>
                        item.url === "" ? (
                          <S.Item key={index} onMouseOver={showMenu}>
                            <a>{item.title}</a>
                          </S.Item>
                        ) : (
                          <S.Item key={index}>
                            <CustomLink url={item.url}>{item.title}</CustomLink>
                          </S.Item>
                        )
                      )}
                    <S.Item>
                      {/* <S.CustomDropdown
                        value="hi"
                        name="language"
                        onChange={handleChange}
                        IconComponent={ArrowDown}
                      >
                        <option value="en">ENGLISH</option>
                        <option value="es">ESPAÑOL</option>
                      </S.CustomDropdown> */}
                    </S.Item>
                  </S.GridWrapper>
                </S.CustomGrid>
              </Grid>
            </S.InnerWrapper>
          </S.Wrapper>
          {show && (
            <S.Dropdown onMouseLeave={hideMenu}>
              <Container maxWidth="lg">
                <S.GridContainer container></S.GridContainer>
              </Container>
            </S.Dropdown>
          )}
        </S.Background>
      </Paper>
      <Paper sx={{ display: { md: "none", xs: "block" } }}>
        <MobileMenu data={dataType} />
      </Paper>
    </header>
  );
};

export default Header;
