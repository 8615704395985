import React, { useEffect, useRef } from "react";
import "./App.css";
import GlobalStyles from "./GlobalStyles";
import Footer from "./components/footer/footer.component";
import Header from "./components/header/header.component";
import Spinner from "./components/spinner/spinner.component";
import Provider from "./context/provider";
import Main from "./layouts/main/main.component";
import Theme from "./theme/theme.component";

import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import PopupDialog from "./components/popup-dialog/popup-dialog.component";

function App() {
  const hideMain = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const home: boolean = location.pathname === "/";
  const links: boolean = location.pathname === "/links";
  const honeygain: boolean = location.pathname === "/honeygain";

  useEffect(() => {
    let animate: ReturnType<typeof setTimeout>;

    if (home) {
      animate = setTimeout(() => {
        if (hideMain.current) {
          hideMain.current.classList.add("show");
          hideMain.current.classList.remove("hide");
        }
      }, 1000);
    }

    return () => clearTimeout(animate);
  }, []);

  return (
    <HelmetProvider>
      <Theme>
        <Provider>
          <div className="App">
            <GlobalStyles />
            {home && <Spinner />}
            <div ref={hideMain}>
              <PopupDialog />
              {!links && !honeygain && <Header />}
              <Main links={links} honeygain={honeygain} />
              {!links && !honeygain && <Footer />}
            </div>
          </div>
        </Provider>
      </Theme>
    </HelmetProvider>
  );
}

export default App;
