import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../pages/home/home";
import Feed from "../../pages/feed/feed";
import SlotGame from "../../pages/slot-game/slot-game";
import * as S from "./main.styles";
import { GlobalDataContext } from "../../context/provider";
import { GetContext } from "../../utils/utils";
import Skeleton from "@mui/material/Skeleton";
import Donations from "../../pages/donations/donations";
import Links from "../../pages/links/links";
import MyLove from "../../pages/my-love/my-love";
import Honeygain from "../../pages/honeygain/honeygain";

interface MainInterface {
  links?: boolean;
  honeygain?: boolean;
}

const Main = ({ links, honeygain }: MainInterface): JSX.Element => {
  const { loading }: { loading: boolean } = GetContext(GlobalDataContext);

  return (
    <>
      {!loading ? (
        <S.Main links={links} honeygain={honeygain}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/feed/" element={<Feed />} />
            <Route path="/slot-game/" element={<SlotGame />} />
            <Route path="/donations/" element={<Donations />} />
            <Route path="/links/" element={<Links />} />
            <Route path="/my-love/" element={<MyLove />} />
            <Route path="/honeygain/" element={<Honeygain />} />
          </Routes>
        </S.Main>
      ) : (
        <Skeleton variant="rectangular" width="100%" />
      )}
    </>
  );
};

export default Main;
